import React, {useCallback, useEffect, useState} from 'react'
import "./PaymentSuccess.scss"

import Footer from "../../components/Footer/Footer";

import 'alpinejs';

const PaymentSuccess = (props) => {

    useEffect(() => {

    }, []);

    return (
        <div className='payment'>
            {/*<Loading/>*/}
            <div className={""}>

                <div className={"card text-center flex flex-col justify-center items-center p-10"}>
                    <div className={"flex flex-col"}>
                        <div>
                            <img width={"60px"} src={"img/icon/success.svg"}/>
                        </div>
                    </div>

                    <p className={"font-rexton text-md pt-4 pb-0  uppercase text-white "}>Payment</p>
                    <p className={"font-rexton text-md pb-4 pb-0 mb-5 uppercase text-white "}>Successful</p>

                </div>
            </div>


        </div>
    )

}

export default PaymentSuccess