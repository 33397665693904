import React, {useCallback, useEffect, useState} from 'react'
import "./Terms.scss"
import Footer from "../../components/Footer/Footer";
import Loading from "../../components/Loading/Loading";

import {makeRequest} from "../../makeRequest";

const Terms = (props) => {

    // const [faq, setFAQ] = useState([]);

    useEffect(() => {
        const fetchData = async () => {

            // const faqRequest = await makeRequest.get("api/faq?populate=*");
            // setFAQ(faqRequest.data.data.attributes.faq)

            const script = document.createElement("script")
            script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY}`
            document.body.appendChild(script)
            // setCountryServed(respGlobal.data);
        };

        fetchData();
    }, []);

    return (
        <div className='terms'>

            <Loading/>
            <div className={"header-section section-padding"}>
                <p className={"font-rexton pt-2 pb-2 uppercase"}>Terms & Conditions</p>
            </div>

            <div className={"container section-padding"}>

                <p><strong>Acceptance of the Terms and Conditions:</strong> This is an agreement between LIKA Social
                    FZ-LLC, a free zone limited liability company registered at the Dubai Development Authority in
                    Dubai, United Arab Emirates, under commercial license number 101791 ("Lika", "we", "us" or "our"),
                    and you ("you", "Your", "your"), a user of the Platform. By using the Platform, you agree to these
                    Terms and Conditions ("Terms") and the <a href={"https://likasocial.com/privacy-policy"}>Privacy
                        Policy</a>.</p>

                <h2>1. Definitions</h2>
                <ul>
                    <li><strong>“Account Holder”:</strong> means a person creating and holding an account on the
                        Platform in the meaning of section 3 below
                    </li>
                    <li><strong>“Payment Gateway”:</strong> means Lika’s contracted payment gateway to facilitate the
                        payments through the Platform, being Mamo Pay (for the United Arab Emirates), or other entity
                        depending on your location.
                    </li>
                    <li><strong>“Plan”:</strong> means an event or activity that is organized by a User or Organizer
                        and is exclusively available to a select group of individuals. Organizers may share a private
                        invite
                        link to allow participants to join by paying the required amount.
                        Upon payment, participants are automatically added to the plan, but the organizer retains the
                        right
                        to refund or remove participants at any time. The plan is considered private, and its access
                        remains
                        restricted to invited individuals, regardless of whether the organizer shares the link publicly
                        or privately.
                    </li>
                    <li><strong>“Organizer”:</strong> means any approved User who is organizing a Plan.</li>
                    <li><strong>“Participant”:</strong> means any User participating to a Plan.</li>
                    <li><strong>“User” or “Users”:</strong> means any user of the Platform, whether it be a natural or a
                        legal person, and whether it be an Account Holder, an Organizer, or a Participant within the
                        meaning defined below, or anyone using the Platform or any part thereof.
                    </li>
                </ul>

                <h2>2. Changes to Terms and Conditions</h2>
                <ul>
                    <li><strong>2.1 Right to Change Terms:</strong> Lika reserves the right, in its sole discretion, to
                        change these Terms ("Updated Terms") from time to time.
                    </li>
                    <li><strong>2.2 Notice of Updated Terms:</strong> Unless Lika makes a change for legal or
                        administrative reasons, Lika will provide reasonable advance notice before the Updated Terms
                        become effective. You agree that Lika may notify you of the Updated Terms by posting them on the
                        Platform.
                    </li>
                    <li><strong>2.3 Acceptance of Updated Terms:</strong> Your use of the Platform after the effective
                        date of the Updated Terms constitutes your agreement to the Updated Terms. You should review
                        these Terms and any Updated Terms before using the Platform.
                    </li>
                    <li><strong>2.4 Effective Date of Updated Terms:</strong> The Updated Terms will be effective as of
                        the time of posting, or such later date as may be specified in the Updated Terms, and will apply
                        to your use of the Platform from that point forward.
                    </li>
                    <li><strong>2.5 Changes and updates to the Platform:</strong> We may update our Platform from time
                        to time, and may change the Content at any time. We do not guarantee that our Platforms, or any
                        Content on them, will be free from errors, omissions, or defects, whether material or otherwise,
                        and assume no responsibility or liability in relation thereto.
                    </li>
                </ul>

                <h2>3. Your Account</h2>
                <ul>
                    <li><strong>3.1 Account Creation:</strong> You must complete the registration process by providing
                        Lika with current, complete, and accurate information as prompted by the applicable registration
                        form. You also will choose a password and a username.
                    </li>
                    <li><strong>3.2 Responsibility for Account:</strong> You are entirely responsible for maintaining
                        the confidentiality of your password and account. Furthermore, you are entirely responsible for
                        any and all activities that occur under your account. You agree to notify Lika immediately of
                        any unauthorized use of your account or any other breach of security.
                    </li>
                    <li><strong>3.3 Liability for Account Misuse:</strong> Lika will not be liable for any loss that you
                        may incur as a result of someone else using your password or account, either with or without
                        your knowledge. You could be held liable for losses incurred by Lika or another party due to
                        someone else using your account or password.
                    </li>
                    <li><strong>3.4 Use of Other Accounts:</strong> You may not use anyone else's account at any time,
                        without the permission of the account holder.
                    </li>
                    <li><strong>3.5 Closure of the Account:</strong> You may close your account at any time by
                        requesting to do so in your Account section of the Platform or contacting Lika on the email
                        info@likasocicial.com. Lika may, at any time without notice, and at our sole discretion,
                        withdraw your account and suspend, restrict or withdraw your access to the Platform, including
                        if we cease the operation of the Platform, or if we believe that your account has been used by
                        someone else or if in our opinion, you are abusing the use of the Platform or if you fail to
                        comply with one or more terms of these Terms.
                    </li>
                    <li><strong>3.6 Account Holder Minimum Age:</strong> An Account Holder must be at least eighteen
                        (18) years old to be able to register, transact or use the Platform.
                    </li>
                </ul>

                <h2>4. Account Security</h2>
                <p>Lika cares about the integrity and security of your personal information. However, Lika cannot
                    guarantee that unauthorized third parties will never be able to defeat the Platform’s security
                    measures or use any personal information you provide to us for improper purposes. You acknowledge
                    that you provide your personal information at your own risk.</p>

                <h2>5. License</h2>
                <p>During the term of this agreement, Lika grants you a limited, non-exclusive, non-transferable license
                    to access the Platform for your personal and non-commercial use in accordance with the Terms.</p>

                <h2>6. Organizing a Plan</h2>
                <ul>
                    <li><strong>6.1 Register as an Organizer (Optional):</strong> A User who wishes to use LIKA’s cost
                        management solution to assist in organizing a Plan and managing the costs of the plan through
                        the Platform must carry out the following process:
                        <br/>
                        <ul>
                            <li><strong>a.</strong> Register on the Platform to become an “Organizer” by clicking on the
                                relevant
                                button;
                            </li>
                            <li><strong>b.</strong> Carry out the Organizer onboarding process by providing to LIKA the
                                required
                                information including, but not limited to:
                                - your bank account IBAN;
                                - your name as registered on your bank account;
                                - your express approval to these Terms and to Lika Sharing Fee (defined below).
                            </li>
                        </ul>
                    </li>

                    <li><strong>6.2 Create the Plan:</strong> Any User can create a plan through make a plan button,
                        you can create the Plan by inputting the necessary information about the Plan as prompted on the
                        Platform,
                        including:
                        <ul>
                            <li>- The location, date, and time of the Plan;</li>
                            <li>- The description of the Plan;</li>
                            <li>- The total number of invited persons to the Plan (the “Invited Participants”), or
                                specifying that the number of Invited Participants is unspecified;
                            </li>
                            <li>- If the User Registers as an Organizer, they get access to Lika’s
                                cost management solution to either request to split the costs after
                                a plan or during or set a book to join fee for user to pay to enter
                                the private plan. The Organizer may request the Users and attendees
                                to pay to join or split the costs at their convenience.
                                <ul>
                                    <li>The Participation Charges (Book to Join),
                                        if any, being the minimum amount that needs to be paid by
                                        the Invited Participants prior to the Go/No Go Date as Charges
                                        to join the plan; or
                                    </li>
                                    <li>Request to split the Charges (Cost Sharing) equally
                                        amongst the Participants, or be split “Ad Hoc”.
                                        In the latter case, you will be prompted to specify the
                                        amount to be paid by each of your Invited Participants.
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        Please note that the Minimum Charges can be set only when the plan is being created and
                        thus must be before the Plan Start and end Date, The Charges (Cost Sharing) Can be set
                        at any time before or after the event and can only be sent to those who have attended
                        or are listed as attending the plan. The Billing session or the cost sharing request that
                        is to be sent is limited to 7 days after the end date of the plan or 7 days after the date
                        of request if that date is after the end date of the plan, whichever is later,
                        where LIKA will close the billing session and send out the payout to the organizer accordingly.
                    </li>
                    <li><strong>6.3 Sharing the Plan:</strong> Share via the Platform or external links.
                        <ul>
                            <li>
                                <strong>6.3.1</strong> Once you provide the information listed in 6.2 above, the
                                Platform will indicate what is
                                the final Participation Charges to be paid by each Participant to your Plan. The
                                Participation
                                Charges is calculated by either
                                <ul>
                                    <li><strong>(i)</strong> dividing the Total Charges by the expected number of
                                        Participants, and adding Lika Sharing Fee to the result (this in case the Total
                                        Charges
                                        is split
                                        equally among the Participants); or
                                    </li>
                                    <li><strong>(ii)</strong> adding the Lika Share Fee to the Ad Hoc Charge that the
                                        Organizer determined for each Invited Participant. If in agreement, you can
                                        confirm
                                        the
                                        Plan by
                                        clicking on “Go Ahead”.
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li><strong>6.4 Accepting a Plan:</strong>
                        <ul>
                            <li>
                                <strong>6.4.1</strong> Once the Invited Participant accepts the invitation to the shared
                                Plan, a
                                payment
                                request for the relevant Participation Charges will be sent to Invited Participant. The
                                payment request remains open for a maximum of seven (7) calendar days from the date
                                where
                                the payment request is made. Once the Invited Participant pays the Participation
                                Charges,
                                he/she will be considered as having confirmed his/her participation to the Plan and will
                                henceforth be considered as a Participant. The Participation Charges will be charged to
                                the
                                Invited Participant’s preferred payment method designated in his/her Lika account.
                            </li>

                            <li>
                                <strong>6.4.2</strong> If the Participation Charges are not determined when the Plan is
                                shared
                                to the Invited
                                Participant, or if there are no Participation Charges, the Invited Participant’s
                                acceptance
                                of the Plan’s invitation will directly confirm his/her participation to the Plan, and
                                he/she
                                will be considered as a Participant.
                            </li>

                            <li><strong>6.4.3</strong> All Charges paid by the Participants will be held by the Mamo
                                Pay, Lika’s
                                contracted
                                payment gateway, in escrow until a minimum of one business day after the date of the
                                Plan,
                                at which date the Organizer will be able to make a Payout Request. If the Payment
                                Request
                                has been made after the date of the Plan, the Charges paid by the Participants will be
                                held
                                by the Mamo Pay, Lika’s contracted payment gateway, in escrow until a minimum of one
                                business day after the closure of the Payment Request, at which date the Organizer will
                                be
                                able to make a Payout Request.
                            </li>

                            <li><strong>6.4.4</strong> The Participant may request, prior to the Plan date by at least
                                one (1)
                                business day,
                                not to attend the Plan by clicking the “Request Refund”. The Organizer will be notified
                                through the Platform of this request. The Organizer will then have the sole discretion
                                whether to accept this request or not. In case he/she accepts, he/she may cancel the
                                participation of the Participant to the Plan. In case of cancellation, the paid Charges
                                will
                                be refunded to the Participant less the Lika Share Fees.
                            </li>
                        </ul>
                    </li>
                    <li><strong>6.5 Managing the Plan:</strong>
                        <ul>
                            <li>
                                <strong>6.5.1</strong> Once an Organizer creates and shares the Plan, he/she will have
                                access to the
                                “Plan Management” page, where he/she will have access to the current status of the
                                invitations sent to the Invited Participants, and will have access on the payment
                                requests current status.
                            </li>
                            <li>
                                <strong>6.5.2</strong> The Organizer may at any time prior to the date of the Plan, or
                                prior to the
                                cancellation of the Plan (should the Plan be cancelled in implementation of clause 6.6
                                below)
                                <ul>
                                    <li><strong>a.</strong> Remove an Invited Participant, and hence cancel the
                                        invitation sent to said
                                        Invited Participant;
                                    </li>
                                    <li><strong>b.</strong> Add new Invited Participants;</li>
                                    <li><strong>c.</strong> Add or modify the Total Charges, Minimum Charges, Go/No Go
                                        Date, or other
                                        information regarding the Plan; or
                                    </li>
                                    <li><strong>d.</strong> Cancel the participation of a Participant and reimburse
                                        his/her Participation
                                        Charges (less the Lika Share Fee).
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>6.5.3</strong> The Organizer may also, within a maximum of seven calendar days
                                after the date of
                                the Plan
                                <ul>
                                    <li><strong>a.</strong> Create a Payment Request by setting the amount of
                                        Participation Charges to be paid by
                                        a Participant; or
                                    </li>
                                    <li><strong>b.</strong> Cancel the participation of a Participant and reimburse
                                        his/her Participation Charges
                                        (less the Lika Share Fee).
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>6.5.4</strong> The Organizer may at any time after the end date of the Plan,
                                request from Lika that the Charges (being the Participation Charges less the Lika Share
                                Fees) be transferred to his preferred bank account indicated in his/her account, by
                                clicking the “Payout” button on the Platform. Lika will cause Mamo Pay, its contracted
                                payment gateway, to pay to the Organizer the Charges paid by the Participants to the
                                Plan. The payout of the Charges will be made within three business days from the date
                                where the Payout Request was made.
                            </li>
                            <li>
                                <strong>6.5.5</strong> The payout process may be delayed should a Participant raise a
                                complaint to Lika
                                claiming that the Plan did not occur. In such case, Lika will coordinate with both the
                                Organizer and the Participant to assess whether the Plan truly occurred or not. Should
                                Lika determine, at its sole discretion, that the Plan did not occur, Lika may refrain
                                from paying out the Charges to the Organizer and refund to each Participant who is
                                requesting a refund the Charges paid by him/her less the Lika Share Fee.
                            </li>
                        </ul>
                    </li>
                    <li><strong>6.6 Canceling a Plan / Participant Complaint:</strong>
                        <ul>
                            <li><strong>6.6.1</strong> The Organizer may cancel a Plan at any time prior to the Plan
                                date and time, at no cost if there is no Payment requested or Minimum charges for the
                                plan.
                            </li>
                            <li><strong>6.6.2</strong> In the case where the Organizer has set minimum charges from the
                                Participants to join the plan, the Organizer will not be able to delete/cancel the plan
                                prior to a full refund or completion of the payout request.
                            </li>
                            <li><strong>6.6.3</strong> In the case where the Organizer has sent a Payment Request, if at
                                least one payment is made, the Organizer will no longer be able to delete the Plan until
                                completely refunding or closing the payout request without.
                            </li>
                            <li><strong>6.6.4</strong> In the case where the Organizer has set Minimum Charges, and a
                                Go/No Go Date: should the Participants’ Participation Charges payment up to the Go/No Go
                                Date fail to amount to a total equal to the Minimum Charges, then the Plan will be
                                automatically cancelled.
                            </li>
                            <li><strong>6.6.5</strong> If the Plan did not occur at the provided date, or at an amended
                                date as notified by the Organizer to the Participants at least one (1) business day
                                prior to the date of the Plan, the Plan will be considered cancelled.
                            </li>
                            <li><strong>6.6.6</strong> Should a Participant request a Refund due to the Plan not having
                                occurred at the provided date, or at an amended date as per the mechanism provided
                                above, or should a Participant wish to raise a complaint to the Organizer with respect
                                to the Plan, Lika will facilitate the communication of the Participant with the Plan
                                Organizer with regards to the Refund request and/or the Participant’s complaint.
                            </li>
                            <li><strong>6.6.7</strong> In all cases of cancellation, all Participation Charges settled
                                by Participants that are still held by Mamo Pay, Lika’s contracted payment gateway, in
                                escrow, will be refunded directly by Lika, less the Lika Share Fees.
                            </li>
                            <li><strong>6.6.8</strong> In all cases where a refund to a Participant is due, and that the
                                refund amount is still held by Mamo Pay, Lika’s contracted payment gateway, in escrow,
                                the refund process will be as follows: the amount refunded will be refunded by applying
                                a credit to the Participant registered credit card, Apple Pay, or Google Pay. Refunds
                                will be processed within 10 to 30 days depending on the issuing bank of the debit/credit
                                card.
                            </li>
                            <li><strong>6.6.9</strong> In all cases where a refund to a Participant would be carried out
                                with the agreement of the Organizer, after the payout of the Charges to the Organizer,
                                the refund process will be dependent on the Organizer and will be coordinated directly
                                between the Participant and the Organizer.
                            </li>
                        </ul>
                    </li>
                </ul>

                <h2><strong>7. Charges and Payments</strong></h2>
                <ul>
                    <li><strong>7.1</strong> Lika is a social media and exchange platform, and does not have any control
                        on the charges
                        of Plans shared or marketed on the Platform, which are determined by the Organizers.
                    </li>
                    <li><strong>7.2</strong> After your confirmation of participation to a Plan organized by an
                        Organizer, Lika: (i) may
                        facilitate your payment of the applicable Participation Charges on behalf of the Organizer as
                        such Organizer’s limited payment collection agent, and (ii) may collect any applicable Lika
                        Share Fees directly from you. Payment of the Charges and Fees in the above manner shall in all
                        cases be considered the same as payment made directly by the Participant to the Organizer or
                        other independent third-party provider or to Lika, as applicable. Charges and Fees will be
                        inclusive of applicable taxes where required by law, including any VAT or sales tax. Charges and
                        Fees paid by you are final and non-refundable, unless otherwise determined by Lika, or by the
                        Organizer, as applicable.
                    </li>
                    <li><strong>7.3</strong> All Fees and Charges are due immediately and payment will be facilitated by
                        Lika using the
                        preferred payment method designated in your account (such payment methods limited to debit or
                        credit card, or Apple Pay, or Google Pay), after which Lika will send you a confirmation message
                        on the Platform, and Mamo Pay, our contracted payment gateway will send you a receipt of payment
                        by email. Lika may also send you in-app notification of confirmation through the Platform. If
                        your primary account payment method is determined to be expired, invalid or otherwise not able
                        to be charged, you agree that Lika may, on its own behalf, and as the Organizer’s limited
                        payment collection agent, use a secondary payment method in your account, if available.
                    </li>
                    <li><strong>7.4</strong> Lika reserves the right to establish, remove and/or revise Lika Share Fees
                        at any time at
                        Lika’s sole discretion.
                    </li>
                    <li><strong>7.5</strong> Lika Share Fees are as follows:</li>
                    <ul>
                        <li><strong>7.5.1</strong> A commission fee of 2.8% to 4% of the Charges depending on the
                            Participation Charges
                            amount, as determined by Lika at its sole discretion.
                        </li>
                    </ul>
                    <li><strong>7.5.2</strong> A fixed fee of ranging between 1 United Arab Emirates Dirhams to 2.5
                        United Arab Emirates
                        Dirhams per transaction depending on the Participation Charges amount per Participant for each
                        Payment Request.
                    </li>

                </ul>

                <h2><strong>8. Paid Services</strong></h2>
                <ul>
                    <li><strong>8.1 Fees.</strong> Lika may at any time charge fees for access to the Platform. However,
                        in no event will
                        you be charged for access to the Platform without your prior agreement, and you may cancel your
                        account at any time. Any fees will be posted prominently on the Platform and in other
                        appropriate locations on the Platform.
                    </li>
                    <li><strong>8.2 Rates.</strong> You will pay all fees and charges incurred through your account at
                        the rates in
                        effect for the billing period when they are incurred. You will be billed for and pay all fees
                        and charges, along with all applicable taxes, relating to your use of the Platform through your
                        account.
                    </li>
                </ul>

                <h2><strong>9. Intellectual Property Rights</strong></h2>
                <ul>
                    <li>The design, trademarks, service marks, and logos of the Platform ("<strong>Marks</strong>"),
                        and the content displayed on the Platform (the “<strong>Content</strong>”) are owned by or
                        licensed
                        to Lika subject to copyright and other intellectual property rights under United Arab Emirates
                        (“<strong>UAE</strong>”)
                        and foreign laws and international conventions. Lika reserves all rights not expressly granted
                        in and
                        to the Platform. You agree to not engage in the use, copying, or distribution of any of the
                        Platform other than
                        expressly permitted.
                    </li>
                    <ul>
                        <li><strong>a.</strong> In these terms and conditions, “Intellectual Property Rights“ means
                            intellectual property
                            rights and includes: (i) copyright, patents, database rights and rights in trademarks,
                            designs, know- how and confidential information (whether registered or unregistered); (ii)
                            applications for registration, and the right to apply for registration, for any of these
                            rights; (iii) all other intellectual property rights and equivalent or similar forms of
                            protection existing anywhere in the world; and (iv) any licenses, permissions or rights to
                            use obtained by a third party owner of intellectual property to use the Content on our
                            Platform; and “Content” means our Platform, including but not limited to the underlying
                            HTML, text, graphics, logos, button icons and images, audio clips, video clips, digital
                            downloads, data compilations (as well as the organization and lay out of our Platforms),
                            software and other content published and made available to you on our Platforms.
                        </li>
                        <li><strong>b.</strong> Except where expressly provided otherwise in these terms of use, you may
                            reproduce and
                            display the Content for your own personal, non-commercial use only. Except for the temporary
                            copy held in your computer’s cache and a single permanent copy for your personal reference,
                            the Content may not otherwise be used, stored, reproduced, published, altered or transmitted
                            in any form or by any means in whole or part without our prior written approval or the
                            written approval of the relevant Intellectual Property Rights owner or licensor.
                        </li>
                        <li><strong>c.</strong> In particular, you may not use any Content to establish, maintain or
                            provide, or assist
                            in establishing, maintaining or providing your own publications, internet site or other
                            means of distribution.
                        </li>
                        <li><strong>d.</strong> If you print off, copy or download any part of our Content in breach of
                            these terms of
                            use, your right to use our Content (including as permitted in these terms of use) will cease
                            immediately and you must, at our option, return or destroy any copies of the Content you
                            have made.
                        </li>
                        <li><strong>e.</strong> Nothing displayed on the Platform should be construed as granting any
                            right of use in
                            relation to any logo, masthead, service marks or trademark displayed on them without the
                            express written consent of the relevant Intellectual Property Rights owner or licensor.
                        </li>

                    </ul>
                </ul>

                <h2><strong>10. User Conduct</strong></h2>
                <ul>
                    <li><strong>10.1 </strong>copying, distributing, or disclosing any part of the Platform in any
                        medium, including without limitation by any automated or non-automated "scraping", or to spam,
                        phish, pharm, pretext, spider, or crawl on or through the Platform;
                    </li>
                    <li><strong>10.2 </strong>using any automated system, including without limitation "robots,"
                        "spiders," "offline readers," etc., to access the Platform;
                    </li>
                    <li><strong>10.3 </strong>transmitting spam, chain letters, or other unsolicited email;</li>
                    <li><strong>10.4 </strong>any unlawful purpose;</li>
                    <li><strong>10.5 </strong>posting inappropriate content or using the Platform inappropriately (as
                        determined by Lika at its sole discretion) such as but not limited to: political or
                        controversial events or posts or use, sexual or explicit or obscene posts or events or use, etc.
                    </li>
                    <li><strong>10.6 </strong>attempting to interfere with, compromise the system integrity or security
                        of, or decipher any transmissions to or from the servers running the Platform;
                    </li>
                    <li><strong>10.7 </strong>taking any action that imposes or may impose, at our sole discretion, an
                        unreasonable or disproportionately large load on the Platform infrastructure;
                    </li>
                    <li><strong>10.8 </strong>uploading invalid data, viruses, worms, or other software agents, or any
                        other type of malicious code on or through the Platform, that will or may affect the
                        functionality or operation of the Platform or of any related website, or third party websites,
                        or the Internet;
                    </li>
                    <li><strong>10.9 </strong>collecting or harvesting any personally identifiable information,
                        including account names, from the Platform;
                    </li>
                    <li><strong>10.10 </strong>using the Platform for any commercial solicitation purposes;</li>
                    <li><strong>10.11 </strong>impersonating another person or otherwise misrepresenting your
                        affiliation with a person or entity, conducting fraud, hiding or attempting to hide your
                        identity;
                    </li>
                    <li><strong>10.12 </strong>interfering with the proper working of the Platform;</li>
                    <li><strong>10.13 </strong>accessing any Content on the Platform through any technology or means
                        other than those provided or authorized by the Platform;
                    </li>
                    <li><strong>10.14 </strong>infringing upon or violating our intellectual property rights or the
                        intellectual property rights of others;
                    </li>
                    <li><strong>10.15 </strong>You may not use the Platform to engage in any of the following prohibited activities: to harass, abuse, insult, harm, defame, slander, disparage, intimidate,
                        or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national
                        origin, or disability;
                    </li>
                    <li><strong>10.16 </strong>submitting false or misleading information; or</li>
                    <li><strong>10.17 </strong>bypassing the measures we may use to prevent or restrict access to the
                        Platform, including without limitation, features that prevent or restrict use or copying of any
                        Content or enforce limitations on use of the Platform or the Content.
                    </li>
                </ul>
                <h2><strong>11. User Content</strong></h2>
                <ul>
                    <li><strong>11.1 Content Ownership.</strong> You retain all ownership rights to content that you
                        uploaded to the Platform.
                    </li>

                    <li><strong>11.2 Content License.</strong> By submitting content to the Platform, you grant Lika a
                        worldwide, non-exclusive, royalty-free, sublicensable, and transferable license to use,
                        reproduce, distribute, prepare derivative works of, display, and perform the content in
                        connection with the Platform and Lika’s (and its successors' and affiliates') business,
                        including without limitation for promoting and redistributing part or all of the Platform (and
                        derivative works thereof) in any media formats and through any media channels.
                    </li>

                </ul>

                <h2><strong>12. Third Party Content</strong></h2>
                <p>Through the Platform, you will have the ability to access and/or use content provided by third
                    parties, including content provided by Organizers such as but not limited to Plans. Lika cannot
                    guarantee that such third-party or Organizer’s content will be free of material you may find
                    objectionable or otherwise. Lika disclaims any responsibility or liability related to your access or
                    use of any third-party or Organizer’s content.</p>

                <h2><strong>13. Booking Plans Through Links to other Websites</strong></h2>
                <p>You may book services or tickets through third party links included in the Platform. </p>

                <h2><strong>14. Links to other Websites or Plans</strong></h2>
                <ul>
                    <li><strong>14.1 Links.</strong> The Platform may contain links to third-party websites or resources
                        or Plans organized by Organizers. You acknowledge and agree that Lika is not responsible or
                        liable for:
                    </li>
                    <ul>
                        <li><strong>a)</strong> the availability or accuracy of such websites or resources or Plans; or
                        </li>
                        <li><strong>b)</strong> the content, products, or services on or available from such websites or
                            resources or Plans.
                        </li>
                    </ul>
                    <li><strong>14.2 No Endorsement.</strong> Links to such websites or resources do not imply any
                        endorsement by Lika of those websites or resources or Plans.
                    </li>

                    <li><strong>14.3 Assumption of Risk.</strong> You acknowledge sole responsibility for and assumes
                        all risk arising from
                        your use of any such websites or resources or participation to any such Plans.
                    </li>

                </ul>

                <h2><strong>15. Privacy</strong></h2>
                <p>For information about how Lika collects, uses, and shares your information, please review our Privacy
                    Policy at <a href="https://likasocial.com/privacy-policy">Privacy Policy</a>. You agree that, by
                    using the Platform, you consent to the collection, use, and sharing (as set forth in the Privacy
                    Policy) of that information (including the transfer of this information to Lebanon, Germany or other
                    countries for Lika’s storage, processing, and use).</p>

                <h2><strong>16. Copyright Policy</strong></h2>
                <p>Lika respects the intellectual property rights of others and expects users of the Platform to do the
                    same. We will respond to notices of alleged copyright infringement that comply with applicable law
                    and are properly provided to us. If you believe that your content has been copied in a way that
                    constitutes copyright infringement, please provide our copyright agent with the following
                    information:</p>
                <ul>
                    <li><strong>a)</strong> a physical or electronic signature of the copyright owner or a person
                        authorized to act on their behalf;
                    </li>
                    <li>
                        <strong>b)</strong> identification of the copyrighted work claimed to have been infringed;
                    </li>
                    <li><strong>c)</strong> identification of the material that is claimed to be infringing or to be the
                        subject of infringing activity and that is to be removed or access to which is to be disabled,
                        and information reasonably sufficient to permit us to locate the material;
                    </li>
                    <li><strong>d)</strong> your contact information, including your address, telephone number, and an
                        email address;
                    </li>
                    <li><strong>e)</strong> a statement by you that you have a good faith belief that use of the
                        material in the manner complained of is not authorized by the copyright owner, its agent, or the
                        law, and
                    </li>
                    <li><strong>f)</strong> a statement that the information in the notification is accurate, and, under
                        penalty of perjury, that you are authorized to act on behalf of the copyright owner.
                    </li>
                </ul>
                <h2><strong>17. Promotions</strong></h2>
                <p>Some Users or Organizers may promote competitions, promotions, prize draws, and other similar
                    opportunities on the Platform ("Third Party Competitions"). Lika is not the sponsor or promoter of
                    these Third Party Competitions and does not bear any responsibility or liability for the actions or
                    inactions of any third parties who organize, administer, or are otherwise involved in any of
                    promotion of these Third Party Competitions. If you wish to participate in any of these Third Party
                    Competitions, you are responsible for reading and ensuring that you understand the applicable rules
                    and any eligibility requirements, and are lawfully able to participate in such Third Party
                    Competitions in your country of residence. If you wish to run your own Third Party Competition on
                    the Platform, you are free to do so provided you obtain our prior written approval and comply with
                    our Terms.</p>

                <h2><strong>18. Suspension and Termination of Access</strong></h2>
                <ul>
                    <li><strong>18.1</strong> Lika may suspend or terminate your access to the Platform immediately
                        without
                        prior notice and without further obligation or liability to you if, in Lika’s sole discretion,
                        it
                        determines that you have breached or failed to comply with any provision(s) of the Terms. Lika
                        may
                        also suspend your access to the Platform or terminate the Terms at any time without written
                        notice
                        to you for any reason whatsoever.
                    </li>
                    <li><strong>18.2</strong> If the Terms are terminated for any reason, you are still bound by the
                        Terms
                        as set out herein. To the extent permitted by applicable law, the disclaimers, limitations on
                        liability, termination, interpretative provisions, and your warranties and indemnities shall
                        survive
                        any termination of the Terms.
                    </li>
                    <li><strong>18.3</strong> Lika has no liability of any kind or any nature whatsoever to you solely
                        by
                        reason of any change, modification, suspension or discontinuance of the Platform in accordance
                        with
                        the Terms. You agree that you shall not make any claim against Lika, including with respect to
                        any
                        lost revenue, profits or opportunities as a result of such change, modification, suspension or
                        discontinuance or on account of any expenditures made or actions taken in reliance on the
                        expected
                        continuation of the Platform or the Terms.
                    </li>
                    <li><strong>18.4</strong> Lika shall not be required to refund to you any amounts prepaid, if any,
                        for
                        use of the Platform if Lika terminated your account or your use of, or access to, the Platform
                        for
                        breach of the Terms. If Lika terminated your access to the Platform without breach on your part,
                        any
                        fees or charges paid by you in advance, if any, shall be refunded on a pro rata basis by
                        applying a
                        credit to your registered credit card, or if that is not possible for any reason, by way of a
                        bank
                        transfer using bank details that you have provided to us.
                    </li>

                </ul>

                <h2><strong>19. Disclaimers</strong></h2>
                <p>The Platform is provided "as is," without any warranties of any kind. To the fullest extent
                    permissible under applicable law, Lika disclaims all such warranties, express or implied, including,
                    but not limited to, warranties of merchantability, fitness for a particular purpose,
                    non-infringement, accuracy, freedom from errors, suitability of content, or availability.</p>

                <h2><strong>20. Limitation on Liability</strong></h2>
                <ul>
                    <li><strong>20.1</strong> Lika will not be responsible or liable for any loss or damages of any kind
                        including but
                        not limited to direct, indirect, incidental punitive and consequential damages, injuries which
                        as a result of your use of the Platform or your purchase of services or of your booking of Plans
                        or attendance to Plans booked through the Platform, whether in contract (including negligence)
                        or otherwise, even if foreseeable, arising under or in connection with:
                        <ul>
                            <li><strong>20.1.1</strong> use of, or inability to use, the Platform including any tools
                                used on or
                                otherwise linked or integrated with the Platform; or
                            </li>
                            <li><strong>20.1.2</strong> use of or reliance on any content displayed on the Platform,
                                including any
                                content displayed on any tools used on or otherwise linked or integrated with the
                                Platform; or
                            </li>
                            <li><strong>20.1.3</strong> use of or reliance on any Plan posted or promoted on the
                                Platform; or
                            </li>
                            <li><strong>20.1.4</strong> use of or reliance on any content of websites linked to the
                                Platform.
                            </li>
                        </ul>
                    </li>

                    <li><strong>20.2</strong> If you are an Organizer, you agree that we will bear no liability towards
                        you for any:
                        <ul>
                            <li><strong>20.2.1</strong> loss of profits, sales, business, or revenue;</li>
                            <li><strong>20.2.2</strong> business interruption;</li>
                            <li><strong>20.2.3</strong> loss of anticipated savings;</li>
                            <li><strong>20.2.4</strong> loss of opportunity, goodwill or reputation; or</li>
                            <li><strong>20.2.5</strong> any indirect or consequential loss or damage.</li>
                        </ul>
                    </li>
                    <li><strong>20.3</strong> Organizer’s Responsibility for External Promotion and Approvals
                        You further agree that, if you engage in any activity or promotion of your private
                        plan outside of the platform (such as advertising the event through public channels
                        or external platforms), you are solely responsible for obtaining any necessary
                        governmental approvals or permits. LIKA shall bear no responsibility for any legal
                        or regulatory issues that arise from such actions. LIKA is a platform exclusively for
                        organizing and managing private plans that are invite-only. Any promotion, advertising,
                        or activity that violates this definition is outside the scope of LIKA's services and
                        is entirely the responsibility of the organizer.
                    </li>
                    <li>
                        <strong>20.4</strong> If you are a Participant, please note that we only provide our Platform
                        for domestic and
                        private use, and that we do not control the content of the Plans, nor the content posted by
                        the
                        Organizers. You agree and acknowledge that you did your own due diligence concerning the
                        Plan
                        and the Organizer prior to deciding to participate to the Plan, and prior to your payment of
                        the
                        Participation Charges due for the Plan. You agree that we will bear no liability towards you
                        for
                        any damage or loss caused to you because of your payment of any Participation Charges, or of
                        your participation to a Plan posted on the Platform.
                    </li>

                    <li><strong>20.5</strong> We will not be liable for any loss or damage caused by a virus,
                        distributed
                        denial-of-service attack, or other technologically harmful material that may infect your
                        computer equipment, computer programs, data or other proprietary material due to your use of
                        the
                        Platform or your downloading of any content on it, or on any website linked to it.
                    </li>

                    <li><strong>20.6</strong> We assume no responsibility for the content of websites linked to the
                        Platform or of
                        Plans
                        posted on the Platform. Such links or posts should not be interpreted as endorsement by us
                        of
                        those linked websites or of those Plans. We will not be liable for any loss or damage that
                        may
                        arise from your use of them or participation to them.
                    </li>

                    <li><strong>20.7</strong> We will not be liable or responsible to any person or entity for any
                        injury, loss, or
                        damage caused or alleged to be caused directly or indirectly as a result of the use or
                        interpretation of the content, or from taking part in any activity or event, featured on our
                        Platform.
                    </li>

                    <li><strong>20.8</strong> You agree and acknowledge that the Platform is an online platform for
                        social
                        interaction,
                        whereby we assist you in creating custom plans tailored to your interests by streamlining
                        the
                        discovery, organizing, sorting, and storing of your experiences and memories. Lika merely
                        provides a technology platform to allow the Users to connect with each others, including
                        when a
                        User becomes an Organizer of a Plan, and other Users Participants to such Plan. The Plans
                        organized and communicated through the Platform are the sole responsibility of the Organizer
                        of
                        such Plan. We do not warrant that the Plan descriptions or other content present on the
                        Platform
                        is accurate, complete, reliable, current, or error-free. If a Plan provided or communicated
                        on
                        the Platform is not as described, your sole remedy is to contact the Organizer of such Plan
                        in
                        application of sections 6.6.4 above. The booking of a Plan by a Participant from an
                        Organizer
                        consists in an agreement between the Participant and said Organizer. Consequently, Lika is
                        not
                        and will not be considered a party to the agreement between the Organizer and the
                        Participant,
                        and shall have no responsibility or liability whatsoever in relation or connection thereto.
                    </li>

                    <li><strong>20.9</strong> We do not guarantee, represent or warrant that your use of the Platform
                        will be
                        uninterrupted, timely, secure or error-free.
                    </li>

                    <li><strong>20.10</strong> You agree that from time to time we may remove the Platform for
                        indefinite
                        periods of
                        time
                        or cancel the Platform at any time, without notice to you.
                    </li>

                    <li><strong>20.11</strong> You expressly agree that your use of, or inability to use, the Platform
                        is at your
                        sole
                        risk.
                    </li>

                    <li><strong>20.12</strong> You expressly agree that your use of, or inability to use, the Platform
                        is at your
                        sole
                        risk. The Platform and all products and services delivered to you through the Platform are
                        (except as expressly stated by us) provided “as is” and “as available” for your use, without
                        any
                        representation, warranties or conditions of any kind, either express or implied, including
                        all
                        implied warranties or conditions of merchantability, merchantable quality, fitness for a
                        particular purpose, durability, title, and non-infringement.
                    </li>

                    <li><strong>20.13</strong> In no case shall we, our directors, officers, employees, affiliates,
                        agents,
                        contractors,
                        interns, suppliers, service providers or licensors be liable for any injury, loss, claim, or
                        any
                        direct, indirect, incidental, punitive, special, or consequential damages of any kind,
                        including, without limitation lost profits, lost revenue, lost savings, loss of data,
                        replacement costs, or any similar damages, whether based in contract, tort (including
                        negligence), strict liability or otherwise, arising from your use of any of the Platform or
                        any
                        service or item procured using the Platform, or for any other claim related in any way to
                        your
                        use of the Platform or any service or item including, but not limited to, any errors or
                        omissions in any content, or any loss or damage of any kind incurred as a result of the use
                        of
                        the Platform (or any content or service or item) posted, transmitted, or otherwise made
                        available via the Platform, even if advised of their possibility.
                    </li>

                    <li><strong>20.14</strong> Neither party will be liable for breach-of-contract damages that are
                        remote or
                        speculative, or that the breaching party could not reasonably have foreseen on entry into
                        this
                        agreement.
                    </li>

                </ul>

                <h2><strong>21. Indemnification</strong></h2>
                <p>You shall indemnify, defend and hold harmless Lika and its collaborators, suppliers and licensors,
                    and their officers, directors, agents, and employees from and against any claim, proceeding, loss,
                    damage, fine, penalty, interest, and expense (including, without limitation, fees for attorneys and
                    other professional advisors) made by any third party due to or arising out of or in connection
                    with</p>
                <ul>
                    <li><strong>a)</strong> your use of the Platform;</li>
                    <li><strong>b)</strong> your breach of this agreement;</li>
                    <li><strong>c)</strong> violation of law;</li>
                    <li><strong>d)</strong> your submission, posting, or transmission of user content to the Platform;
                        or
                    </li>
                    <li><strong>e)</strong> violation of the rights of a third party.</li>
                </ul>
                <h2><strong>22. Dispute Resolution</strong></h2>
                <ul>
                    <li><strong>22.1 Informal Dispute Resolution.</strong> The parties shall cooperate to attempt to
                        informally resolve any dispute arising out of this agreement before submitting the dispute to
                        the competent courts.
                    </li>

                    <li><strong>22.2 Competent Court.</strong> In the event of failure of such negotiations, the courts
                        of Dubai, United Arab Emirates, shall have exclusive jurisdiction to settle any dispute or claim
                        arising out of or in connection with these Terms.
                    </li>

                </ul>

                <h2><strong>23. Feedback</strong></h2>
                <p>We welcome any comment, question, and communication at <a
                    href={"mailto:info@likaosocial.com"}>info@likaosocial.com</a></p>

                <h2><strong>24. General Provisions</strong></h2>
                <ul>
                    <li><strong>24.1 Governing Law.</strong> This agreement and these Terms will be governed, construed,
                        and enforced in
                        accordance with the laws of Dubai in the United Arab Emirates without regard to its conflict of
                        laws rules.
                    </li>
                    <li><strong>24.2 Assignment.</strong> You may not assign this agreement and/or these Terms and/or
                        any of your rights
                        or obligations under this agreement and/or these Terms to any other party without our prior
                        written consent. We may assign the rights and obligations under this agreement and/or these
                        Terms to any other entity.
                    </li>
                    <li>24.3 Notices.
                        <ul>
                            <li><strong>24.3.1 Method of Notice.</strong> The parties will give all notices and
                                communications
                                between
                                the parties in writing by (i) personal delivery, (ii) a nationally recognized,
                                next-day
                                courier service, (iii) first-class registered or certified mail, postage prepaid, or
                                (iv) email to the address that a party has notified to be that party's address for
                                the
                                purposes of this section.
                            </li>
                            <li><strong>24.3.2 Receipt of Notice.</strong> A notice given under this agreement will be
                                effective
                                on:

                                <ul>
                                    <li><strong>24.3.2.1</strong> the other party's receipt of it, or</li>
                                    <li><strong>24.3.2.2</strong> if mailed, the earlier of the other party's receipt of
                                        it and the fifth
                                        business day after mailing it.
                                    </li>
                                    <li><strong>24.3.2.3</strong> if faxed or emailed, on the date sent by facsimile or
                                        e-mail (with
                                        confirmation
                                        of transmission) if sent during normal business hours of the recipient, and on
                                        the
                                        next
                                        business day if sent after normal business hours of the recipient.
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>


                    <li><strong>24.4 Waiver</strong>
                        <ul>

                            <li><strong>24.4.1 Affirmative Waivers.</strong> Neither party's failure or neglect to
                                enforce any rights
                                under this agreement will be deemed to be a waiver of that party's rights.
                            </li>
                            <li><strong>24.4.2 Written Waivers.</strong> A waiver or extension is only effective if it
                                is in writing
                                and signed by the
                                party granting it.
                            </li>
                            <li><strong>24.4.3 No General Waivers.</strong> A party's failure or neglect to enforce any
                                of its rights
                                under this
                                agreement will not be deemed to be a waiver of that or any other of its rights.
                            </li>
                            <li><strong>24.4.4 No Course of Dealing.</strong> No single or partial exercise of any right
                                or remedy
                                will preclude any
                                other or further exercise of any right or remedy.
                            </li>
                        </ul>
                    </li>
                    <li><strong>24.5 Severability.</strong> If any part of this agreement is declared unenforceable or
                        invalid, the
                        remainder
                        will continue to be valid and enforceable.
                    </li>
                </ul>


            </div>
            <Footer/>
        </div>
    )

}

export default Terms