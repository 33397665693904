import React, {useRef, useState} from "react";
import "./Footer.scss";
import ReCAPTCHA from "react-google-recaptcha"
import {makeRequest} from "../../makeRequest";
import {capitalizeFirstLetter} from "../../script/Utils";

const Footer = () => {
    const [formSubmitting, setFormSubmitting] = useState(false);
    const [toastVisible, setToastVisible] = useState(false);
    const [toastMessage, setToastMessage] = useState("");

    const onSubmit = async (e) => {
        e.preventDefault();
        const data = Array.from(e.target.elements)
            .filter((input) => input.name)
            .reduce((obj, input) => Object.assign(obj, {[input.name]: input.value}), {});

        window.grecaptcha.ready(_ => {
            window.grecaptcha
                .execute(process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY, {action: "homepage"})
                .then(async token => {
                    if (data["email"] == "") {
                        data["email"] = null;
                    }
                    data["gRecaptchaToken"] = token;
                    let request_data = {};
                    request_data["data"] = data;
                    setFormSubmitting(true);
                    const submitFormRequest = await makeRequest.post("api/subscribers", request_data).then(result => {
                        e.target.reset();
                        setFormSubmitting(false);
                        setToastMessage(capitalizeFirstLetter("Your request was submitted successfully"));
                    }).catch(reason => {
                        setFormSubmitting(false);
                        console.log(reason);
                        if (reason.response?.data?.error.hasOwnProperty("message")) {
                            setToastMessage(capitalizeFirstLetter(reason.response.data.error.message));
                        } else {
                            setToastMessage("Something went wrong submitting your request. Please try again later.");
                        }
                    });
                    setToastVisible(true);
                    setTimeout(
                        function () {
                            setToastVisible(false);
                        }
                            .bind(this),
                        3500
                    );
                })
        })


        console.log("refresh prevented");
    };

    return (
        <div className="footer section-padding">
            <div className="container-flow relative mx-auto flex flex-col md:flex-row justify-center ">


                <div
                    className={"h-100 mx-auto text-center flex flex-1 flex-col md:flex-col items-center justify-center md:hidden "}>
                    <div className={"text-center flex flex-1 align-center justify-center"}>
                        <img className={"logo-footer mb-3 object-contain"} src={"img/lika_logo.png"}/>
                    </div>
                    <p className={"text-xxsm"}>Copyright 2024 All rights reserved</p>
                </div>

                <div className={"mx-auto text-left flex flex-row md:flex-row"}>
                    <div className={"md:mx-auto text-left flex flex-col md:flex-col p-3"}>
                        <p className={"font-rexton text-xsm text-gold uppercase"}>COMPANY</p>
                        <p className={"text-xsm"}><a href={"about"}>About us</a></p>
                        <p className={"text-xsm"}><a href={"partners"}>Partners</a></p>
                    </div>

                    <div className={"md:mx-auto text-left flex flex-col md:flex-col p-3"}>
                        <p className={"font-rexton text-xsm text-gold uppercase"}>Social</p>
                        <p className={"text-xsm"}><a href={"https://www.instagram.com/likasocial.app"}>Instagram</a></p>
                        <p className={"text-xsm"}><a href={"https://www.linkedin.com/company/lika-social"}>Linkedin</a></p>
                        <p className={"text-xsm"}><a href={"https://www.tiktok.com/@lika.social"}>Tiktok</a></p>
                        <p className={"text-xsm"}><a href={"https://x.com/LIKASOCIALAPP"}>X</a></p>
                    </div>
                </div>

                <div
                    className={"h-100 mx-auto text-center md:flex flex-1 flex-col md:flex-col items-center justify-center hidden "}>
                    <div className={"text-center flex flex-1 align-center justify-center"}>
                        <img className={"logo-footer mb-3 object-contain"} src={"img/lika_logo.png"}/>
                    </div>
                    <p className={"text-xxsm"}>Copyright 2024 All rights reserved</p>
                </div>

                <div className={"mx-auto text-left flex flex-row md:flex-row"}>
                    <div className={"mx-auto text-left flex flex-col md:flex-col p-3"}>
                        <p className={"font-rexton text-xsm text-gold uppercase"}>LEGAL</p>
                        <a href={"terms-conditions"}><p className={"text-xsm"}>T&Cs</p></a>
                        <a href={"privacy-policy"}><p className={"text-xsm"}>Privacy Policy</p></a>
                        <a href={"data-deletion"}><p className={"text-xsm"}>Data Deletion</p></a>
                    </div>
                    <div className={"mx-auto text-left flex flex-col md:flex-col p-3"}>
                        <p className={"font-rexton text-xsm text-gold uppercase"}>CONTACT</p>
                        <p className={"text-xsm"}><a href={"mailto:info@likasocial.com"}>info@likasocial.com</a></p>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Footer;
